import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [smsCode, setSmsCode] = useState('');
  const navigate = useNavigate();

 const handleLogin = async (event, loginUsername = username, loginPassword = password) => {
  // 防止默认表单提交行为，如果事件对象存在
  if (event) {
    event.preventDefault();
  }

  try {
    const response = await fetch('https://wechat.haodaqi.net/api/custom-login/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username: loginUsername, password: loginPassword }),
    });

    const data = await response.json();
    if (response.ok) {
      const isSuperuser = data.isSuperUser === "1" ? "true" : "false";
      localStorage.setItem('is_superuser', isSuperuser);
      localStorage.setItem('token', data.token);
      localStorage.setItem('username', loginUsername);
      
      console.log('Login successful:', data);
      navigate('/');
    } else {
      console.error('登录失败:', data.error);
    }
  } catch (error) {
    console.error('登录请求出错', error);
  }
};


   const handleSendVerificationCode = async () => {
       if (isSendingCode) return;  // 如果已经在发送状态，则不执行任何操作

    setIsSendingCode(true);
    try {
      const response = await fetch('https://wechat.haodaqi.net/api/send-sms/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone_number: phoneNumber }),
      });

      const data = await response.json();
      if (response.ok) {
        console.log('验证码发送成功:', data);
      } else {
        console.error('验证码发送失败:', data.error);
      }
    } catch (error) {
      console.error('验证码发送请求出错', error);
    }
    
    setTimeout(() => {
      setIsSendingCode(false);
    }, 60000);
  
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();
    try {
        const response = await fetch('https://wechat.haodaqi.net/api/create-user-web/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password, phone_number: phoneNumber, sms_code: smsCode }),
        });

        const data = await response.json();
        if (response.ok) {
        console.log('用户创建成功:', data);
        handleLogin(null, username, password); // 调用 handleLogin 进行自动登录
        } else {
        console.error('用户创建失败:', data.error);
        }
    } catch (error) {
        console.error('用户创建请求出错', error);
    }
};


 return (
  <div style={{ marginLeft: '20px' }}>
    <h3>登录</h3>
    <form onSubmit={handleLogin}>
      <div style={{ marginBottom: '10px' }}>
        <label htmlFor="username">用户名:  </label>
        <input
          type="text"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div style={{ marginBottom: '10px' }}>
        <label htmlFor="password">密码:  </label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div>
        <button
          type="submit"
          style={{
            marginTop: '10px',
            cursor: 'pointer',
          }}
        >
          登录
        </button>
      </div>
    </form>

    <h3>创建用户</h3>
    <form onSubmit={handleCreateUser}>
      <div style={{ marginBottom: '10px' }}>
        <label htmlFor="username">用户名:  </label>
        <input
          type="text"
          id="newUsername"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div style={{ marginBottom: '10px' }}>
        <label htmlFor="newPassword">密码:  </label>
        <input
          type="password"
          id="newPassword"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div style={{ marginBottom: '10px' }}>
        <label htmlFor="phoneNumber">手机号码:  </label>
        <input
          type="text"
          id="phoneNumber"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <button
            type="button"
            onClick={handleSendVerificationCode}
            disabled={isSendingCode}  // 当 isSendingCode 为 true 时禁用按钮
            style={{ marginLeft: '10px' }}
            >
            {isSendingCode ? '60S后重发' : '发送验证码'}
        </button>

      </div>
      <div style={{ marginBottom: '10px' }}>
        <label htmlFor="smsCode">验证码:  </label>
        <input
          type="text"
          id="smsCode"
          value={smsCode}
          onChange={(e) => setSmsCode(e.target.value)}
        />
      </div>
      <div>
        <button
          type="submit"
          style={{
            marginTop: '10px',
            cursor: 'pointer',
          }}
        >
          创建用户
        </button>
      </div>
    </form>
  </div>
);
}

export default LoginPage;
