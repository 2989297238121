// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './LoginPage';
import DashboardPage from './DashboardPage';
import NewArticlePage from './NewArticlePage';
import DifferentChart from './DifferentChart'; // 示例文章组件
import ChartName from './ChartName';
import ArticleDetailPage from './ArticleDetailPage';
import ArticleListPage from './ArticleListPage';
import NameArticleDetailPage from './NameArticleDetailPage';
import GroupUsersPage from './GroupUsersPage';
import UserArticlesPage from './UserArticlesPage'; 
import GptPage from './GptPage'; 
import EditPage from './EditPage'; 

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/edit-article/:articleId" element={<EditPage />} />
        <Route path="/" element={<ArticleListPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/new-article" element={<NewArticlePage />} />
        <Route path="/differentchart" element={<DifferentChart />} /> 
        <Route path="/chartname" element={<ChartName />} /> 
        <Route path="/articledetail/:title" element={<ArticleDetailPage />} />
        <Route path="/namearticledetail/:title" element={<NameArticleDetailPage />} />
        <Route path="/groups/:groupName/users" element={<GroupUsersPage />} />
        <Route path="/chatgpt" element={<GptPage />} />
        <Route path="/user-articles/:username" element={<UserArticlesPage />} />
      </Routes>
    </Router>
  );
}

export default App;
