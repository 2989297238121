
// ChartName.js
import React, { useState } from 'react';
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  Link,
  Button
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router-dom';

function ChartName() {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const navigate = useNavigate();
  const drawerWidth = 240;

  const handleExternalNavigation = (url) => {
    window.location.href = url;
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ marginRight: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
            名字
          </Typography>
          <Button color="inherit" startIcon={<AddCircleOutlineIcon />} onClick={() => navigate('/new-article')}>
            新建文章
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            paddingTop: 8,
          },
        }}
        variant="persistent"
        anchor="left"
        open={drawerOpen}
      >
        <Divider />
        <List>
          {['我的文章', '其他人的文章', 'ChatGPT 体验'].map((text) => (
           <ListItem button key={text} onClick={() => {
            if (text === '其他人的文章') {
              handleNavigation('/DifferentChart');
            }else if (text === 'ChatGPT 体验') {
                handleExternalNavigation('https://haodaqi.net/special');
            }else if(text === '我的文章'){
                handleNavigation('/dashboard');
              }
          }}>
            <ListItemText primary={text} />
          </ListItem>
          ))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          transition: (theme) => theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          marginLeft: `${drawerOpen ? drawerWidth-220 : -200}px`, // Adjust margin based on the drawer state
          position: 'relative',

        }}
      >
        {/* Removed Toolbar to avoid extra top margin */}
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start', // Align items to the start of the flex container
            paddingTop: '64px', // Offset for the AppBar height
          }}>
          <Link
            href="#"
            onClick={() => navigate('/NameArticle')}
            sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              姚祺
            </Typography>
          </Link>
        </Box>
        {/* 其他内容 */}
      </Box>
    </Box>
  );
}

export default ChartName;
