import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css'; // 选择您喜欢的样式
import logo from './logo2.png'; 
import {
  useTheme,
  useMediaQuery,
} from '@mui/material';

function ArticleDetailPage() {
  const contentRef = useRef(null); // 创建一个 ref 来引用文章内容的 DOM 元素
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [article, setArticle] = useState([]);
  const { title } = useParams();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [goldenRatioWidth, setGoldenRatioWidth] = useState(null);
  const [WordgoldenRatioWidth, setWordGoldenRatioWidth] = useState(null);
  const globalStyles = `
    .article-content img {
      max-width: 100%;
      height: auto;
    }
  `;

  
useEffect(() => {
  // 根据isMobile状态来更新goldenRatioWidth
  if (isMobile) {
    setWordGoldenRatioWidth(10);
    setGoldenRatioWidth(100);
  } else {
    setGoldenRatioWidth(100 * 0.85);
    setWordGoldenRatioWidth(14);
  }
  // 重新应用代码高亮，以防屏幕尺寸改变影响代码块样式
  if (contentRef.current) {
      const codeBlocks = contentRef.current.querySelectorAll('pre code');
      codeBlocks.forEach((block) => {
        hljs.highlightBlock(block);
      });
    }
}, [isMobile]); // 当isMobile变化时，重新运行此效果

useEffect(() => {
  const token = localStorage.getItem('token');
  const storedUsername = localStorage.getItem('username');
  setIsLoggedIn(!!token);
  if (storedUsername) {
    setUsername(storedUsername);
  }
  const encodedTitle = encodeURIComponent(title);
  fetch(`https://wechat.haodaqi.net/api/articles/title/${encodedTitle}/`)
    .then(response => response.json())
    .then(data => {
      setArticle(data);
      // 确保代码块在文章内容加载后高亮
     if (contentRef.current) {
      const codeBlocks = contentRef.current.querySelectorAll('pre code');
      codeBlocks.forEach((block) => {
        hljs.highlightBlock(block);
      });
    }
    })
    .catch(error => console.error('Error fetching article:', error));
}, [title, article]); // 当文章标题变化时，重新获取文章内容

  
  const handleLogout = (event) => {
    event.preventDefault();  // 阻止链接默认行为
    event.stopPropagation(); // 阻止事件冒泡
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    setIsLoggedIn(false);
  };

  const handleProtectedLinkClick = (e, path) => {
    if (!isLoggedIn) {
      e.preventDefault(); // 阻止默认链接行为
      navigate('/login'); // 导航到登录页面
    } else {
      navigate(path); // 导航到指定的路径
    }
  };

  return (
    <div 
      style={{
        marginTop:'10px',
        display: 'flex',
        flexDirection: 'column', // 垂直方向排列
        justifyContent: 'center', // 水平居中
        alignItems: 'center', // 垂直居中

      }}> 
      <style>
        {`
          .article-content img {
            max-width: 100%;
            height: auto;
          }
          .article-content pre {
            padding:15px;
            color: white;
            background-color: black;
            border: 1px solid #ddd;
            overflow-x: auto;
          }
          .article-content pre code {
            color: white;
            font-family: 'Courier New', Courier, monospace;
          }
        `}
      </style>
      <div
          style={{
            backgroundColor: '#ffa343', // 
            color: 'white', // 文字颜色为白色
            width: `${goldenRatioWidth}vw`,
            display: 'flex', // 使用flex布局使图标和文字在一行显示
            alignItems: 'center', // 垂直居中对齐
            height:'26px'
      }}
      >
        <a href="/">
          <img src={logo} alt="Logo" width="18px" height="18px" style={{marginLeft: '3px', border: '1px solid black'}}/>
        </a>        
        <a href="/" style={{ color: 'black', fontSize:  `${WordgoldenRatioWidth}px`, marginLeft: '5px', fontWeight: 'bold', fontFamily: 'sans-serif', textDecoration: 'none'}}></a>
        <a href="/DifferentChart" 
          style={{ fontSize: `${WordgoldenRatioWidth}px`, fontFamily: 'unset', marginLeft:  `${WordgoldenRatioWidth*0.5}px`, marginRight:  `${WordgoldenRatioWidth*0.5}px`, color: 'black', textDecoration: 'none' }}
          onClick={(e) => handleProtectedLinkClick(e, '/DifferentChart')}>
          项目
        </a>
        <span style={{ fontSize:  `${WordgoldenRatioWidth}px`, color: 'black' }}>|</span>
        {isLoggedIn && (
           <a href="/dashboard" style={{ fontSize:`${WordgoldenRatioWidth}px`, fontFamily: 'unset', marginLeft:  `${WordgoldenRatioWidth*0.5}px`, marginRight:  `${WordgoldenRatioWidth*0.5}px`,color: 'black', textDecoration: 'none' }}>我的</a>
        )}
        {isLoggedIn && (
           <span style={{ fontSize: `${WordgoldenRatioWidth}px`, color: 'black' }}>|</span>
        )}
        <a href="/chatgpt" 
          style={{ fontSize:  `${WordgoldenRatioWidth}px`, fontFamily: 'unset', marginLeft:  `${WordgoldenRatioWidth*0.5}px`, marginRight:  `${WordgoldenRatioWidth*0.5}px`, color: 'black', textDecoration: 'none' }}
          onClick={(e) => handleProtectedLinkClick(e, '/chatgpt')}>
          AI
        </a>
        <span style={{ fontSize:  `${WordgoldenRatioWidth}px`,color: 'black' }}>|</span>
          <CopyToClipboard text={window.location.href} onCopy={() => alert('文章链接已复制')}>
          <p style={{fontSize: `${WordgoldenRatioWidth}px`,fontFamily:'unset',color: 'black', marginLeft:  `${WordgoldenRatioWidth*0.5}px`, marginRight:  `${WordgoldenRatioWidth*0.5}px`,cursor: 'pointer' }}>
            分享
          </p>
         </CopyToClipboard>
        <span style={{ fontSize:  `${WordgoldenRatioWidth}px`,color: 'black' }}>|</span>
        <a href="/new-article" 
          style={{ fontSize:  `${WordgoldenRatioWidth}px`, fontFamily: 'unset', marginLeft:  `${WordgoldenRatioWidth*0.5}px`, marginRight:  `${WordgoldenRatioWidth*0.5}px`, color: 'black', textDecoration: 'none' }}
          onClick={(e) => handleProtectedLinkClick(e, '/new-article')}>
          发布
        </a>
        <div style={{ display: 'flex', alignItems: 'center' ,marginLeft: 'auto',}}>
          {isLoggedIn && (
            <span style={{ fontSize:  `${WordgoldenRatioWidth}px`, color: 'black', marginRight: `${WordgoldenRatioWidth*0.5}px` }}>
              {username}
            </span>
          )}
          {isLoggedIn && (
          <span style={{ fontSize:  `${WordgoldenRatioWidth}px`, color: 'black' ,marginRight: `${WordgoldenRatioWidth*0.5}px`}}>|</span>
          )}
          <a href={isLoggedIn ? "/" : "/login"} 
            style={{ fontSize:  `${WordgoldenRatioWidth}px`, color: 'black', marginRight:'3px'}}
            onClick={isLoggedIn ? handleLogout : null}>
            {isLoggedIn ? 'logout' : 'login'}
          </a>
        </div>
      </div>

      <div
       style={{ 
        width: `${goldenRatioWidth}vw`, // 使用黄金比例的宽度
        minHeight: '100vh', // 充满整个视口高度
        backgroundColor: '#F6F5F0', // 设置背景颜色
      }}>
        <p>
          {article.title}
        </p>
        <p style={{ fontSize: '10px', color: '#888'}}>
          cearte at: {new Date(article.created_at).toLocaleDateString()} by: {article.author}
        </p>
        <div
          dangerouslySetInnerHTML={{ __html: article.content }}
          className="article-content"
          style={{
            backgroundColor: '#F6F5F0',
            width: `${goldenRatioWidth}vw`,
            overflow: 'auto',
            wordWrap: 'break-word'
          }}
        />
      </div>
      <footer
        style={{
          fontSize: '10px',
          color: '#888',
          width: `${goldenRatioWidth}vw`,
          textAlign: 'center', // 文本居中对齐
          backgroundColor: '#F6F5F0', // 设置背景颜色
          borderTop: '1px solid #ffa343', // 添加上边线并设置颜色
          height:'25px',
          paddingTop:'5px'
        }}
      >
        <a href="/login">指南</a> | <a href="/login">FAQ</a> | <a href="/login">加入我们</a> | <a href="/login">联系方式</a>
      </footer>
    </div>
  );
}

export default ArticleDetailPage;
