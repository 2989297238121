import React, { useState, useEffect } from 'react';
import {
  Typography,
  Link,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import logo from './logo2.png'; 
import hao from './HAO.png'; 
import { useNavigate } from 'react-router-dom';

function DifferentChart() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [agents, setAgents] = useState([]); // 用于存储群组数据
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [goldenLineRatioWidth, setGoldencLineRatioWidth] = useState(null);
  const [goldenRatioWidth, setGoldenRatioWidth] = useState(null);
  const [WordgoldenRatioWidth, setWordGoldenRatioWidth] = useState(null);



  
  const handleLogout = (event) => {
    event.preventDefault();  // 阻止链接默认行为
    event.stopPropagation(); // 阻止事件冒泡
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    setIsLoggedIn(false);
  };
  const handleProtectedLinkClick = (e, path) => {
    if (!isLoggedIn) {
      e.preventDefault(); // 阻止默认链接行为
      navigate('/login'); // 导航到登录页面
    } else {
      navigate(path); // 导航到指定的路径
    }
  };

  useEffect(() => {
    // 根据isMobile状态来更新goldenRatioWidth
    if (isMobile) {
      setGoldenRatioWidth(100);
      setWordGoldenRatioWidth(10);
    } else {
      setGoldenRatioWidth(100 * 0.85);
      setWordGoldenRatioWidth(14);
    }
  }, []); // 当isMobile变化时，重新运行此效果

  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedUsername = localStorage.getItem('username');
    setIsLoggedIn(!!token);
    if (storedUsername) {
      setUsername(storedUsername);
    }
    if (!token) {
      console.error('No token found');
      return;
    }
    // 在组件加载时调用API
    fetch('https://wechat.haodaqi.net/api/agents')
      .then(response => response.json())
      .then(data => setAgents(data)) // 假设响应直接返回代理数组
      .catch(error => console.error('Error fetching agents:', error));
  }, []);

  const handleAgentClick = (agent) => {
    const token = localStorage.getItem('token');
    // 构建目标URL，并附加token
    const targetUrl = `https://haodaqi.net?token=${encodeURIComponent(token)}`;
    window.location.href = targetUrl; // 或者使用 window.open(targetUrl, '_blank') 在新标签页打开
  };

  return (
    <div 
      style={{
        marginTop:'10px',
        display: 'flex',
        flexDirection: 'column', // 垂直方向排列
        justifyContent: 'center', // 水平居中
        alignItems: 'center', // 垂直居中

      }}> 
      <div
          style={{
            backgroundColor: '#ffa343', // 
            color: 'white', // 文字颜色为白色

            width: `${goldenRatioWidth}vw`,
            display: 'flex', // 使用flex布局使图标和文字在一行显示
            alignItems: 'center', // 垂直居中对齐
            height:'26px'
      }}
      >
        <a href="/">
          <img src={logo} alt="Logo" width="18px" height="18px" style={{marginLeft: '3px', border: '1px solid black'}}/>
        </a>
        <a href="/" style={{ color: 'black', fontSize:  `${WordgoldenRatioWidth}px`, marginLeft: '5px', fontWeight: 'bold', fontFamily: 'sans-serif', textDecoration: 'none'}}></a>
        <a href="/DifferentChart" 
          style={{ fontSize: `${WordgoldenRatioWidth}px`, fontFamily: 'unset', marginLeft:  `${WordgoldenRatioWidth*0.5}px`, marginRight:  `${WordgoldenRatioWidth*0.5}px`, color: 'black', textDecoration: 'none' }}
          onClick={(e) => handleProtectedLinkClick(e, '/DifferentChart')}>
          项目
        </a>
        <span style={{ fontSize:  `${WordgoldenRatioWidth}px`, color: 'black' }}>|</span>
        {isLoggedIn && (
           <a href="/dashboard" style={{ fontSize:`${WordgoldenRatioWidth}px`, fontFamily: 'unset', marginLeft:  `${WordgoldenRatioWidth*0.5}px`, marginRight:  `${WordgoldenRatioWidth*0.5}px`,color: 'black', textDecoration: 'none' }}>我的</a>
        )}
        {isLoggedIn && (
           <span style={{ fontSize: `${WordgoldenRatioWidth}px`, color: 'black' }}>|</span>
        )}
        <a href="/chatgpt" 
          style={{ fontSize:  `${WordgoldenRatioWidth}px`, fontFamily: 'unset', marginLeft:  `${WordgoldenRatioWidth*0.5}px`, marginRight:  `${WordgoldenRatioWidth*0.5}px`, color: 'black', textDecoration: 'none' }}
          onClick={(e) => handleProtectedLinkClick(e, '/chatgpt')}>
          AI
        </a>
        <span style={{ fontSize:  `${WordgoldenRatioWidth}px`,color: 'black' }}>|</span>
        <a href="/new-article" 
          style={{ fontSize:  `${WordgoldenRatioWidth}px`, fontFamily: 'unset', marginLeft:  `${WordgoldenRatioWidth*0.5}px`, marginRight:  `${WordgoldenRatioWidth*0.5}px`, color: 'black', textDecoration: 'none' }}
          onClick={(e) => handleProtectedLinkClick(e, '/new-article')}>
          发布
        </a>
        <div style={{ display: 'flex', alignItems: 'center' ,marginLeft: 'auto',}}>
          {isLoggedIn && (
            <span style={{ fontSize:  `${WordgoldenRatioWidth}px`, color: 'black', marginRight: `${WordgoldenRatioWidth*0.5}px` }}>
              {username}
            </span>
          )}
          {isLoggedIn && (
          <span style={{ fontSize:  `${WordgoldenRatioWidth}px`, color: 'black' ,marginRight: `${WordgoldenRatioWidth*0.5}px`}}>|</span>
          )}
          <a href={isLoggedIn ? "/" : "/login"} 
            style={{ fontSize:  `${WordgoldenRatioWidth}px`, color: 'black', marginRight:'3px'}}
            onClick={isLoggedIn ? handleLogout : null}>
            {isLoggedIn ? 'logout' : 'login'}
          </a>
        </div>
      </div>
      
  
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))',
          gap: '10px',
          flexGrow: 1,
          width: `${goldenRatioWidth}vw`,
          backgroundColor: '#F6F5F0',
        }}
      >
        {agents.map((agent, index) => (
          <div
            key={index}
            onClick={() => handleAgentClick(agent)}
            style={{ 
              display: 'flex', 
              flexDirection: 'row', // 修改为水平排列
              color: 'inherit', 
              marginBottom: '5px',
              cursor: 'pointer', 
              textDecoration: 'none', 
              lineHeight: 'normal',
              alignItems: 'center', // 确保内容在行内居中
            }}
          >
            <img 
              src={hao} 
              alt="代理标志" 
              style={{marginLeft:'10px' ,width: '60px', height: '60px', marginRight: '10px' ,marginTop: '-10px' }} // 调整图片大小和右边距
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p style={{ fontSize: '14px', fontFamily: 'sans-serif', fontWeight: '400', marginBottom: '0' }}>
                {agent.name}
              </p>
              <p style={{ fontSize: '10px', color: '#888', marginTop: '5px' }}>
                {agent.description}    
              </p>
              <p style={{ fontSize: '10px', color: '#007bff', marginTop: '5px'}}>
                点击进入   
              </p>
            </div>
          </div>
        ))}
      </div>

      <footer
        style={{
          fontSize: '10px',
          color: '#888',
          width: `${goldenRatioWidth}vw`,
          textAlign: 'center', // 文本居中对齐
          backgroundColor: '#F6F5F0', // 设置背景颜色
          borderTop: '1px solid #ffa343', // 添加上边线并设置颜色
          height:'25px',
          paddingTop:'5px'
        }}
      >
        <a href="/login">指南</a> | <a href="/login">FAQ</a> | <a href="/login">加入我们</a> | <a href="/login">联系方式</a>
      </footer>
    </div>
  );
}

export default DifferentChart;
