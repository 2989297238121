import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';


function NameArticleDetailPage() {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [article, setArticle] = useState(null);
  const { title } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const drawerWidth = 240;

  useEffect(() => {
    if (isMobile) {
      setDrawerOpen(false);
    }
  }, [isMobile]);

  useEffect(() => {
    const encodedTitle = encodeURIComponent(title);
    fetch(`https://haodaqi.net/api/articles/title/${encodedTitle}/`)
      .then(response => response.json())
      .then(data => setArticle(data))
      .catch(error => console.error('Error fetching article:', error));
  }, [title]);

  const handleExternalNavigation = (url) => {
    window.location.href = url;
  };

  const handleNavigation = (path) => {
    navigate(path);
  }

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleBack = () => {
    navigate(-1); // 导航回上一个页面
  };

  
  if (!article) {
    return <div>正在加载 ...</div>;
  }

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
        <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ marginRight: 2 }}
          >
            <MenuIcon />
          </IconButton>
          {/* 返回按钮 */}
          <IconButton
            color="inherit"
            onClick={handleBack}
            sx={{ marginRight: 2 }}
          >
            <ArrowBackIcon />
          </IconButton>
          {!isMobile && (
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
            文章详情
          </Typography>
          )}
          <Box sx={{ flexGrow: 1}} />
          <CopyToClipboard text={window.location.href}
            onCopy={() => alert('文章链接已复制')}>
          <Button color="inherit">
            分享
          </Button>
        </CopyToClipboard>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            paddingTop: 8,
          },
        }}
        variant={isMobile ? 'temporary' : 'persistent'}
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerToggle}
      >
        <Divider />
        <List>
          {['我的文章', '其他人的文章', 'ChatGPT 体验'].map((text) => (
            <ListItem button key={text} onClick={() => {
                if (text === '其他人的文章') {
                  handleNavigation('/DifferentChart');
                }else if (text === 'ChatGPT 体验') {
                    handleExternalNavigation('https://haodaqi.net/special');
                  }else if(text === '我的文章'){
                    handleNavigation('/dashboard');
                  }
              }}>
                <ListItemText primary={text} />
              </ListItem>
          ))}
        </List>
        </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: `calc(100% - ${drawerOpen ? drawerWidth : 0}px)`,
          marginTop: '64px', // AppBar height
          marginLeft: `${drawerOpen ? (isMobile ? drawerWidth : drawerWidth - 220) : (isMobile ? 0 : -200)}px`,
        }}
      >
        <Typography variant="h5" component="h2" sx={{ marginBottom: 2 }}>
          {article.title}
        </Typography>
        <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
          项目名称: {article.project_name}
        </Typography>
        <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
          撰写人: {article.author}
        </Typography>
        <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
          发布时间: {new Date(article.created_at).toLocaleDateString()}
        </Typography>
        <div dangerouslySetInnerHTML={{ __html: article.content }} />
        {/* 这里可以添加更多文章内容 */}
    </Box>
  </Box>
  );
}

export default NameArticleDetailPage;
