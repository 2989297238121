import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

function UserArticlesPage() {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [articles, setArticles] = useState([]);
  const { username } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const drawerWidth = 240;

  useEffect(() => {
    if (isMobile) {
      setDrawerOpen(false);
    }
  }, [isMobile]);

  useEffect(() => {
    fetch(`https://haodaqi.net/api/articles/user/${encodeURIComponent(username)}/`)
      .then(response => response.json())
      .then(data => setArticles(data.articles))
      .catch(error => console.error('Error fetching articles:', error));
  }, [username]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleExternalNavigation = (url) => {
    window.location.href = url;
  };

  const handleBack = () => {
    navigate(-1); // 导航回上一个页面
  };

  const handleArticleClick = (title) => {
    navigate(`/namearticledetail/${encodeURIComponent(title)}`); 
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ marginRight: 2 }}
          >
            <MenuIcon />
          </IconButton>
          {/* 返回按钮 */}
          <IconButton
            color="inherit"
            onClick={handleBack}
            sx={{ marginRight: 2 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
            文章列表: {username}
          </Typography>
          {/* 其他工具栏内容 */}
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            paddingTop: 8,
          },
        }}
        variant={isMobile ? 'temporary' : 'persistent'}
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerToggle}
      >
        <Divider />
        <List>
          {['我的文章', '其他人的文章', 'ChatGPT 体验'].map((text) => (
            <ListItem button key={text} onClick={() => {
                if (text === '其他人的文章') {
                  handleNavigation('/DifferentChart');
                }else if (text === 'ChatGPT 体验') {
                    handleExternalNavigation('https://haodaqi.net/special');
                  }else if(text === '我的文章'){
                    handleNavigation('/dashboard');
                  }
              }}>
                <ListItemText primary={text} />
              </ListItem>
          ))}
        </List>
        </Drawer>
      <Box
        component="main"
        sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            marginLeft: `${drawerOpen ? (isMobile ? drawerWidth : drawerWidth - 220) : (isMobile ? 0 : -200)}px`,
        }}
        >
        <Toolbar />
        <ul>
        {articles.length > 0 ? (
            articles.map((article, index) => (
            <li key={index} onClick={() => handleArticleClick(article.id)} style={{ cursor: 'pointer' }}>
              <strong>{article.title}</strong> - {article.created_at}
            </li>
          ))
            ) : (
            <Typography variant="subtitle1">
                暂无文章
            </Typography>
            )}
        </ul>
      </Box>
    </Box>
  );
}

export default UserArticlesPage;
